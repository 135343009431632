import { Subtitle } from "../../Components/Text";
import Button from "../../Components/Button";
import {
    CancelOutlined,
    Description,
    HelpOutline, Label,
    LockOpen,
    Logout,
    LogoutOutlined,
    Visibility,
    VisibilityOff,
} from "@mui/icons-material";
import Styled from "./style";
import useAuth from "../../Hooks/useAuth";
import theme from "../../theme";
import { useState } from "react";
import Modal from "../../Components/Modal";
import TextField from "../../Components/Input/TextField";
import { IconButton, InputAdornment, Tooltip } from "@mui/material";
import Alert from "../../Components/Alert";
import ActionModal from "../../Components/ActionModal";
import { profileText } from "../../Constants/Text";
import { useLocalStorage } from "../../Hooks/useLocalStorage";
import { useNavigate } from "react-router-dom";
import Utils from "../../Utils";
import { apiUrls } from "../../Constants/apiUrls";
import { forgotResponse } from "../../Types/API";
import useAxios from "../../Hooks/useAxios";
import { useForm } from "react-hook-form";

type ProfileProps = {
  show: Boolean;
  handleShow: React.Dispatch<React.SetStateAction<boolean>>;
};

const changePasswordFormDefault = {
  newPassword: "",
  confirmPassword: "",
  verificationCode: ""
};

const Profile = ({ show, handleShow }: ProfileProps) => {
  const { auth, setAuth } = useAuth();
  const [showlogOutModal, setShowlogOutModal] = useState(false);
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] = useState(false);
  const [showPwdChangeSuccess, setShowPwdChangeSuccess] = useState(false);
  const [showPwdChangeFail, setShowPwdChangeFail] = useState(false);
  const setLocalAuth = useLocalStorage("auth", {})[1];
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    getValues,
    reset,
    formState
  } = useForm({ defaultValues: changePasswordFormDefault });
  const { fetchData } = useAxios();

  const handleLogout = () => {
    setAuth({});
    setLocalAuth({});
    navigate("/");
  };

  const sendConfirmationEmail = async (userEmail: string) => {
    try {
      const res = await fetchData(
        apiUrls.auth.forgot(userEmail), "GET"
      ) as { data:forgotResponse };
      if (res?.data!=null) {
        alert(`Email de confirmación enviado a: ${res.data.CodeDeliveryDetails.Destination}`);
      } else {
        alert("Error al enviar código de confirmación");
      }
    } catch (error) {
      alert("Error al enviar código de confirmación");
      console.error("Error al enviar código de confirmación:", error);
    }
  };

  const resetPasswordUser = async (reqBody:any) => {
    try {    
        const response = await fetchData(
          apiUrls.auth.reset, 'POST', reqBody
        );
        if (response?.status === 201){
          // console.log('Password cambiado con éxito');
          setShowPwdChangeSuccess(true);
          setTimeout(() => {
            setShowPwdChangeSuccess(false);
            setShowPasswordModal(false);
            reset();
          }, 2500);
        } else {
          console.error('Error al cambiar su password');
          throw Error;
        }
    } catch (error) {
      console.error('Error al cambiar su password:', error);
      setShowPwdChangeFail(true);
      setTimeout(() => setShowPwdChangeFail(false), 2500);
    }
  };

  const onSubmit = async (data: any)  => {
    try {
      
      const { newPassword, confirmPassword, verificationCode } = getValues();

      const reqBody = {
        "email":auth?.user,
        "newPassword":newPassword,
        "verificationCode":verificationCode
      };

      resetPasswordUser(reqBody);

    } catch (error) {
      console.error('Error al cambiar su password:', error);
    }

  };

  return (
    <Styled.Profile data-show={show}>
      <Styled.Overlay onClick={() => handleShow(false)} />

      <Styled.Wrapper>
        <Styled.CloseIconWrapper>
          <CancelOutlined
            onClick={() => handleShow(false)}
            sx={{
              color: theme.colors.white,
              ...theme.iconSize,
            }}
          />
        </Styled.CloseIconWrapper>
        <Styled.Info>
          <Subtitle type="3" color={theme.colors.white}>
            {auth?.fullName}
          </Subtitle>
          <Subtitle type="3" color={theme.colors.white}>
            {auth?.user}
          </Subtitle>
          <Subtitle type="3" color={theme?.colors?.white}>
            {Utils.capitalize(auth?.role)}
          </Subtitle>
        </Styled.Info>
        <Styled.Actions>
          <Button
            variant="text"
            startIcon={<LockOpen />}
            {...register("newPassword")}
            onClick={() => {
              sendConfirmationEmail(String(auth?.user));
              setShowPasswordModal(true);
            }}
            sx={{
              color: theme.colors.white,
            }}
          >
            {profileText.password.button}
          </Button>
          <Button
            variant="text"
            startIcon={<Logout />}
            onClick={() => setShowlogOutModal(true)}
            sx={{
              color: theme.colors.white,
            }}
          >
            {profileText.logout.button}
          </Button>
        </Styled.Actions>
      </Styled.Wrapper>
      <ActionModal
        showModal={showlogOutModal}
        title={profileText.logout.modal.title}
        onCancel={() => setShowlogOutModal(false)}
        onOk={handleLogout}
        icon={
          <LogoutOutlined
            style={{
              color: theme.colors.accent,
              width: "56px",
              height: "56px",
              transform: "scale(-1, 1)",
            }}
          />
        }
      />

      <Modal
        open={showPasswordModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        modalContentStyle={{flexBasis: "min-content"}}
      >
        <Styled.PasswordModalContent>
          <Styled.Form onSubmit={handleSubmit(onSubmit)}>
            <Subtitle type="3">{profileText.password.modal.title+""}</Subtitle>
            <Subtitle type="4">{profileText.password.modal.instructions}</Subtitle>

            <Styled.PasswordModalInput>
              <Subtitle type="2">{profileText.password.modal.new} 
                <Tooltip style={{ padding: 0, marginLeft:5 }} title={profileText.password.modal.tooltip}>
                  <IconButton>
                    <HelpOutline />
                  </IconButton>
                </Tooltip>
              </Subtitle>
              <TextField
                id="newPassword"
                fullWidth
                type={`${showPassword ? "text" : "password"}`}
                placeholder="Nueva contraseña" 
                {...register("newPassword", {
                  required: "",
                  validate: (p: string) =>
                    /^(?=\S)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[\^$*.\[\]{}()?\"!@#%&/\\,><'\`:;|_~+=-]).{8,}\S$/.test(
                      p
                    ),
                })}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() =>
                          setShowPassword((visibility) => !visibility)
                        }
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Styled.PasswordModalInput>

            <Styled.PasswordModalInput>
              <Subtitle type="2">{profileText.password.modal.confirm}</Subtitle>
              <TextField
                id="confirmPassword" 
                fullWidth
                type={`${showPasswordConfirmation ? "text" : "password"}`}
                placeholder="Confirmar nueva contraseña"
                {...register("confirmPassword", {
                  required: "",
                  validate: (p) => p === getValues().newPassword,
                })}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() =>
                          setShowPasswordConfirmation((visibility) => !visibility)
                        }
                      >
                        {showPasswordConfirmation ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Styled.PasswordModalInput>

            <Styled.PasswordModalInput>
              <Subtitle type="2">{profileText.password.modal.code}</Subtitle>
              <TextField
              id="verificationCode"
              fullWidth 
              type="text"
              placeholder="Codigo de confirmación"
              {...register("verificationCode", {
                required: "",
                validate: (v: string) => v?.trim().length >= 1,
              })}
              />
            </Styled.PasswordModalInput>

            <Styled.PasswordModalActions>
              <Button
                variant="outlined"
                startIcon={<CancelOutlined />}
                onClick={() => {
                  reset();
                  setShowPassword(false);
                  setShowPasswordModal(false);
                }}
              >
                {profileText.password.modal.cancel}
              </Button>

              <Button
                type="submit"
                variant="contained"
                startIcon={<Description />}
                disabled={!formState.isValid}
              >
                {profileText.password.modal.accept}
              </Button>
            </Styled.PasswordModalActions>
          </Styled.Form>
        </Styled.PasswordModalContent>
      </Modal>
      <Alert
        show={showPwdChangeSuccess}
        message={profileText.password.update.success}
      />
      <Alert
        show={showPwdChangeFail}
        message={profileText.password.update.failure}
        severity="error"
      />
    </Styled.Profile>
  );
};

export default Profile;
